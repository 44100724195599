import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { font, color, ease, device } from "../../layout/global"
import { CountryDropdown } from "react-country-region-selector"
// import { useBreakpoint } from 'gatsby-plugin-breakpoints'
// import Slider from "react-slick"
import SubmitButton from "../SubmitButton"
import TermsCheckbox from "../TermsCheckbox"
import fbTrack from "../../hooks/fbTrack"


import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { ConsentGtag } from "../../utils/consentGtag"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const axios = require("axios")

const FormContact = ({ id, info, lang, location, smallerSpacing }) => {

  const [country, setCountry] = useState("")
  const [option, setOption] = useState(null)
  const [showLoader, setShowLoader] = useState(false)

  const [successful, setSuccessful] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [focused, setFocused] = useState(false)

  const validityString = useRef(null)
  const validityStringEmail = useRef(null)


  const triggerRef = useRef(null),
    input1Ref = useRef(null),
    input2Ref = useRef(null),
    input3Ref = useRef(null),
    input4Ref = useRef(null),
    input5Ref = useRef(null),
    input6Ref = useRef(null),
    inputField1 = useRef(null),
    inputField2 = useRef(null),
    inputField3 = useRef(null),
    inputField4 = useRef(null),
    inputField5 = useRef(null),
    inputField6 = useRef(null)

  function showFileName() {
    let fileName = document.getElementById("file-upload")
    let inputName = document.getElementById("file-upload-readonly")

    if (fileName.files.item(0)) {
      inputName.value = fileName.files.item(0).name
      inputName.defaultValue = info.field10
    }
  }

  function doSubmit(e) {
    e.preventDefault()
    setShowLoader(true)
    ConsentGtag()
    var formData = new FormData()
    formData.append("name", document.querySelector("#fc-name").value)
    formData.append("email", document.querySelector("#fc-email").value.toLowerCase())
    formData.append("tel", document.querySelector("#fc-tel").value)
    formData.append("country", country)
    formData.append("postalcode", document.querySelector("#fc-postal-code").value)
    formData.append("lang", lang)

    var configs = new Array()

    if (option === "1") {
      formData.append("option", option)
      if (document.querySelector("#microram-checkbox").checked) {
        formData.append("checkbox-microram", document.querySelector("#microram-checkbox").value)
        configs.push('Microram');
      }
      if (document.querySelector("#turboram-checkbox").checked) {
        formData.append("checkbox-turboram", document.querySelector("#turboram-checkbox").value)
        configs.push('Turboram');
      }
      if (document.querySelector("#rotoram-checkbox").checked) {
        formData.append("checkbox-rotoram", document.querySelector("#rotoram-checkbox").value)
        configs.push('Rotoram');
      }
      if (document.querySelector("#modulram-checkbox").checked) {
        formData.append("checkbox-modulram", document.querySelector("#modulram-checkbox").value)
        configs.push('Modulram');
      }
      if (document.querySelector("#compactram-checkbox").checked) {
        formData.append("checkbox-compactram", document.querySelector("#compactram-checkbox").value)
        configs.push('Compactram');
      }
      if (document.querySelector("#electram-checkbox").checked) {
        formData.append("checkbox-electram", document.querySelector("#electram-checkbox").value)
        configs.push('Electram');
      }
      if (document.querySelector("#anelar-checkbox").checked) {
        formData.append("checkbox-anelar", document.querySelector("#anelar-checkbox").value)
        configs.push('Anelar');
      }
      if (document.querySelector("#anelec-checkbox").checked) {
        formData.append("checkbox-anelec", document.querySelector("#anelec-checkbox").value)
        configs.push('Anelec');
      }
      if (document.querySelector("#modulgaz-checkbox").checked) {
        formData.append("checkbox-modulgaz", document.querySelector("#modulgaz-checkbox").value)
        configs.push('Modulgaz');
      }
      if (document.querySelector("#modulec-checkbox").checked) {
        formData.append("checkbox-modulec", document.querySelector("#modulec-checkbox").value)
        configs.push('Modulec');
      }
      if (document.querySelector("#moldurampn-checkbox").checked) {
        formData.append("checkbox-moldurampn", document.querySelector("#moldurampn-checkbox").value)
        configs.push('Moldurampn');
      }
      if (document.querySelector("#leitoes-checkbox")) {
        if (document.querySelector("#leitoes-checkbox").checked) {
          formData.append("checkbox-leitoes", document.querySelector("#leitoes-checkbox").value)
          configs.push('Leitoes');
        }
      }
      if (document.querySelector("#other-checkbox").checked) {
        formData.append("checkbox-other", document.querySelector("#other-checkbox").value)
        configs.push('Other');
      }
      formData.append("message", document.querySelector("#fs-message").value)
      document.querySelector("#terms-01").checked && formData.append("terms01", document.querySelector("#terms-01").value)
      document.querySelector("#terms-02").checked && formData.append("terms02", document.querySelector("#terms-02").value)

      if (typeof window !== "undefined") {
        window.gtag("event", "form_contacto", {
          form: "Formulário de Contacto",
          assunto: "Informação sobre Produtos",
          gama: configs.join(',') ?? ''
        })
      }
    } else if (option === "2") {
      formData.append("option", option)
      formData.append("serial-no", document.querySelector("#serial-no").value)
      formData.append("file", document.getElementById("file-upload").files[0])
      formData.append("message", document.querySelector("#fs2-message").value)
      document.querySelector("#terms-01").checked && formData.append("terms01", document.querySelector("#terms-01").value)
      document.querySelector("#terms-02").checked && formData.append("terms02", document.querySelector("#terms-02").value)
      if (typeof window !== "undefined") {
        window.gtag("event", "form_contacto", {
          form: "Formulário de Contacto",
          assunto: "Assistência Técnica",
          gama: ''
        })
      }
    } else if (option === "3") {
      formData.append("option", option)
      formData.append("company", document.querySelector("#company").value)
      formData.append("website", document.querySelector("#website").value)
      formData.append("message", document.querySelector("#fs3-message").value)
      document.querySelector("#terms-01").checked && formData.append("terms01", document.querySelector("#terms-01").value)
      document.querySelector("#terms-02").checked && formData.append("terms02", document.querySelector("#terms-02").value)

      if (typeof window !== "undefined") {
        window.gtag("event", "form_contacto", {
          form: "Formulário de Contacto",
          assunto: "Representação Comercial",
          gama: ''
        })
      }
    }


    axios
      .post("https://ramalhos.com/form-contact.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then(res => {
        var aux = res.data.split("<br>")
        var len = aux.length
        if (aux[len - 1].includes("success")) {
          setShowLoader(false)
          onSuccess()
          fbTrack("trackCustom", "Formulário - Site Principal")
        } else {
          setShowLoader(false)
          onError()
        }
      })
  }

  useEffect(() => {
    if (!showLoader) {
      let I1 = input1Ref.current,
        I2 = input2Ref.current,
        I3 = input3Ref.current,
        I4 = input4Ref.current,
        I5 = input5Ref.current,
        I6 = input6Ref.current

      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            anticipatePin: 1,
            trigger: triggerRef.current,
            toggleActions: "play none none none",
            start: "center 80%",
          },
        })
        .fromTo([I1, I2, I3, I4, I5, I6], { opacity: 0, y: 25 }, { opacity: 1, y: 0, duration: 0.5, stagger: 0.15 })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!showLoader) {
      if (document.getElementById("contact-form")) {
        document.getElementById("contact-form").style.opacity = "1"
      }
      switch (lang) {
        case "pt":
          validityString.current = `Campo obrigatório.`
          validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
          break
        case "en":
          validityString.current = `Mandatory field.`
          validityStringEmail.current = `The e-mail must be in the following format: xxx@yyy.zzz`
          break
        case "es":
          validityString.current = `Campo obligatorio.`
          validityStringEmail.current = `El correo electrónico debe tener el siguiente formato: xxx@yyy.zzz`
          break
        case "mx":
          validityString.current = `Campo obligatorio.`
          validityStringEmail.current = `El correo electrónico debe tener el siguiente formato: xxx@yyy.zzz`
          break
        case "fr":
          validityString.current = `Champ obligatoire.`
          validityStringEmail.current = `L'e-mail doit être au format suivant : xxx@yyy.zzz`
          break
        default:
          validityString.current = `Campo obrigatório.`
          validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
          break
      }
      inputField1.current.setCustomValidity(validityString.current)
      inputField2.current.setCustomValidity(validityString.current)
      inputField3.current.setCustomValidity(validityString.current)
      inputField5.current.setCustomValidity(validityString.current)
    }
  }, [lang])

  useEffect(() => {
    if (focused && !showLoader) {
      !inputField1.current.validity.valueMissing
        ? inputField1.current.setCustomValidity("")
        : inputField1.current.setCustomValidity(validityString.current)

      if (inputField2.current.validity.typeMismatch) {
        inputField2.current.setCustomValidity(validityStringEmail.current)
      } else if (inputField2.current.validity.valueMissing) {
        inputField2.current.setCustomValidity(validityString.current)
      } else {
        inputField2.current.setCustomValidity("")
      }

      !inputField3.current.validity.valueMissing
        ? inputField3.current.setCustomValidity("")
        : inputField3.current.setCustomValidity(validityString.current)
      !inputField5.current.validity.valueMissing
        ? inputField5.current.setCustomValidity("")
        : inputField5.current.setCustomValidity(validityString.current)
      setFocused(false)
    }
  }, [focused])

  const onSuccess = () => {
    document.getElementById("contact-form").style.opacity = "0"
    if (typeof window !== undefined) {
      window.gtag('event', 'conversion', { 'send_to': 'AW-11288001937/03dSCOSm1OMYEJH7xIYq' });
    }
    setTimeout(function () {
      setSuccessful(true)
      setTimeout(function () {
        document.getElementById("contactosucesso").style.opacity = "1"
        setTimeout(function () {
          document.getElementById("contactosucesso").style.opacity = "0"
          setTimeout(function () {
            setSuccessful(false)
            setTimeout(function () {
              document.getElementById("contact-form").style.opacity = "1"
            }, 150)
          }, 300)
        }, 3500)
      }, 150)
    }, 300)
  }
  const onError = () => {
    document.getElementById("contact-form").style.opacity = "0"
    setTimeout(function () {
      setHasError(true)
      setTimeout(function () {
        document.getElementById("error").style.opacity = "1"
        setTimeout(function () {
          document.getElementById("error").style.opacity = "0"
          setTimeout(function () {
            setHasError(false)
            setTimeout(function () {
              document.getElementById("contact-form").style.opacity = "1"
            }, 150)
          }, 300)
        }, 3500)
      }, 150)
    }, 300)
  }
  const doFocus = () => {
    setFocused(true)
  }

  const selectCountry = val => {
    setCountry(val)
  }
  const selectedOption = e => {
    setOption(e.target.value)
  }

  return (
    <Wrapper id={id} ref={triggerRef} smallerSpacing={smallerSpacing}>
      <div className="container">
        {showLoader ?
          <LoaderStyle>
            <div className="spinner-container">
              <div className="loading-spinner">
              </div>
            </div>
          </LoaderStyle>
          :
          hasError ? (
            <div id="error" >
              <h3>{info.error1}</h3>
              <p>{info.error2}</p>
            </div>
          ) : !successful ? (
            <form id="contact-form" onSubmit={doSubmit} method="POST" autoComplete="off">
              <div className="input-wrapper" id="fcw-name" ref={input1Ref}>
                <input
                  ref={inputField1}
                  onChange={doFocus}
                  className="fc-input"
                  id="fc-name"
                  name="name"
                  type="text"
                  placeholder={info.field1}
                  required
                ></input>
                <label htmlFor="fc-name" className="label">
                  {info.field1} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fcw-email" ref={input2Ref}>
                <input
                  ref={inputField2}
                  onChange={doFocus}
                  className="fc-input"
                  id="fc-email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  required
                ></input>
                <label htmlFor="fc-email" className="label">
                  {info.field2} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fcw-tel" ref={input3Ref}>
                <input
                  ref={inputField3}
                  onChange={doFocus}
                  className="fc-input"
                  id="fc-tel"
                  name="tel"
                  type="text"
                  placeholder="Tel"
                  pattern="[0-9]*"
                  required
                ></input>
                <label htmlFor="fc-tel" className="label">
                  {info.field3} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fcw-country" ref={input4Ref}>
                <CountryDropdown
                  id="fc-country"
                  name="country"
                  defaultOptionLabel=""
                  required
                  value={country}
                  onChange={val => selectCountry(val)}
                  ref={inputField4}
                />
                <label htmlFor="fc-country" className="label">
                  {info.field4} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fcw-postal-code" ref={input5Ref}>
                <input
                  ref={inputField5}
                  onChange={doFocus}
                  className="fc-input"
                  id="fc-postal-code"
                  name="postal-code"
                  type="text"
                  placeholder="Código Postal"
                  required
                ></input>
                <label htmlFor="fc-postal-code" className="label">
                  {info.field5} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="select-wrapper" ref={input6Ref}>
                <h2>{info.text1}</h2>
                {/* eslint-disable-next-line */}
                <select id="secondary-choice" defaultValue={0} onChange={selectedOption} ref={inputField6}>
                  <option value={0} disabled hidden>
                    {info.dropdown1}
                  </option>
                  <option value={1}>{info.dropdown2}</option>
                  <option value={3}>{info.dropdown3}</option>
                  <option value={2}>{info.dropdown4}</option>
                </select>
              </div>
              <div id="fcw-secondary">
                {option === "1" && (
                  <div className="form-secondary">
                    <h3>{info.text2}</h3>
                    <div className="input-wrapper category-picker">
                      <div className="category-picker-item">
                        <input type="checkbox" id="microram-checkbox" value="microram" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="microram-checkbox" id="microram-label" className="category-label">
                          Microram
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="turboram-checkbox" value="turboram" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="turboram-checkbox" id="turboram-label" className="category-label">
                          Turboram
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="rotoram-checkbox" value="rotoram" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="rotoram-checkbox" id="rotoram-label" className="category-label">
                          Rotoram
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="modulram-checkbox" value="modulram" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="modulram-checkbox" id="modulram-label" className="category-label">
                          Modulram
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="compactram-checkbox" value="compactram" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="compactram-checkbox" id="compactram-label" className="category-label">
                          Compactram
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="electram-checkbox" value="electram" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="electram-checkbox" id="electram-label" className="category-label">
                          Electram
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="anelar-checkbox" value="anelar" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="anelar-checkbox" id="anelar-label" className="category-label">
                          Anelar
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="anelec-checkbox" value="anelec" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="anelec-checkbox" id="anelec-label" className="category-label">
                          Anelec
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="modulgaz-checkbox" value="modulgaz" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="modulgaz-checkbox" id="modulgaz-label" className="category-label">
                          Modulgaz
                        </label>
                      </div>
                      <div className="category-picker-item">
                        <input type="checkbox" id="modulec-checkbox" value="modulec" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="modulec-checkbox" id="modulec-label" className="category-label">
                          Modulec
                        </label>
                      </div>
                      {lang === "pt" && (
                        <>
                          <div className="category-picker-item">
                            <input type="checkbox" id="moldurampn-checkbox" value="moldurampn" className="category-checkbox" />
                            <span className="fs-checkmark"></span>
                            <label htmlFor="moldurampn-checkbox" id="moldurampn-label" className="category-label">
                              Pastel de Nata
                            </label>
                          </div>
                          <div className="category-picker-item">
                            <input type="checkbox" id="leitoes-checkbox" value="leitoes" className="category-checkbox" />
                            <span className="fs-checkmark"></span>
                            <label htmlFor="leitoes-checkbox" id="leitoes-label" className="category-label">
                              Leitões
                            </label>
                          </div>
                        </>
                      )}
                      <div className="category-picker-item">
                        <input type="checkbox" id="other-checkbox" value="other" className="category-checkbox" />
                        <span className="fs-checkmark"></span>
                        <label htmlFor="other-checkbox" id="other-label" className="category-label">
                          {info.other}
                        </label>
                      </div>
                    </div>
                    <div className="input-wrapper-message">
                      <textarea className="fs-message" id="fs-message" placeholder={info.field6}></textarea>
                      <label htmlFor="fs-message" className="category-label message-label">
                        {info.field6}
                      </label>
                      <TermsCheckbox
                        hasTerms
                        hasShare
                        text1={info.button1}
                        text2={info.button2}
                        text3={info.button3}
                        language={lang}
                      />
                    </div>
                    <div className="submit-wrapper">
                      <SubmitButton text={info.submit} />
                    </div>
                  </div>
                )}
                {option === "2" && (
                  <div className="form-secondary">
                    <div className="input-wrapper">
                      <input id="serial-no" type="text" className="fs-input" placeholder="Tipo de Equipamento" />
                      <label htmlFor="serial-no" className="fs-label">
                        {info.field9}
                      </label>
                    </div>
                    <div className="input-wrapper file-upload">
                      <input type="text" id="file-upload-readonly" placeholder={info.field10} readOnly="readonly" />
                      <input id="file-upload" type="file" onChange={showFileName} />
                      <label id="file-upload-label" htmlFor="file-upload">
                        {info.field10}
                      </label>
                      <input type="hidden" name="MAX_FILE_SIZE" value="10000000" />
                    </div>
                    <div className="input-wrapper">
                      <textarea className="fs-message" id="fs2-message" placeholder={info.field6}></textarea>
                      <label htmlFor="fs2-message" className="category-label message-label2">
                        {info.field6}
                      </label>
                      <TermsCheckbox
                        hasTerms
                        hasShare
                        text1={info.button1}
                        text2={info.button2}
                        text3={info.button3}
                        language={lang}
                      />
                    </div>
                    <div className="submit-wrapper">
                      <SubmitButton text={info.submit} />
                    </div>
                  </div>
                )}
                {option === "3" && (
                  <div className="form-secondary">
                    <div className="input-wrapper">
                      <input id="company" type="text" className="fs-input" placeholder="Empresa" />
                      <label htmlFor="company" className="fs-label">
                        {info.field7}
                      </label>
                    </div>
                    <div className="input-wrapper">
                      <input id="website" type="text" className="fs-input" placeholder="Website" />
                      <label htmlFor="website" className="fs-label">
                        {info.field8}
                      </label>
                    </div>
                    <div className="input-wrapper">
                      <textarea className="fs-message" id="fs3-message" placeholder={info.field6}></textarea>
                      <label htmlFor="fs3-message" className="category-label message-label3">
                        {info.field6}
                      </label>
                      <TermsCheckbox
                        hasTerms
                        hasShare
                        text1={info.button1}
                        text2={info.button2}
                        text3={info.button3}
                        language={lang}
                      />
                    </div>
                    <div className="submit-wrapper">
                      <SubmitButton text={info.submit} />
                    </div>
                  </div>
                )}
              </div>
            </form>
          ) : (
            <div id="contactosucesso">
              <span></span>
              <h3>{info.confirmation1}</h3>
              <p>{info.confirmation2}</p>
            </div>
          )
        }
      </div>
    </Wrapper >
  )
}

export default FormContact

const LoaderStyle = styled.div`
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid rgba(225,6,0,1); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}`
const Wrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  z-index: 1;
  position: relative;

  @media ${device.mobileP} {
    padding: 5rem 0;
  }

  @media ${device.tabletP} {
    padding: ${props => (props.smallerSpacing ? "7rem 0" : "15rem 0")};
  }

  #successful {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto 1fr;
    justify-content: center;
    span {
      display: block;
      width: 7px;
      height: 12px;
      border: solid ${color.red};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      justify-self: center;
      margin-bottom: 25px;
    }
    h3 {
      ${font.financierBoldN};
      font-size: 2.5rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${font.robotoMedium};
      font-size: 1.5rem;
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  #error {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto auto;
    justify-content: center;
    h3 {
      ${font.financierBoldN};
      font-size: 1.4rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${font.robotoMedium};
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  .container {
    @media ${device.tabletP} {
      width: 90%;
      max-width: 900px;
    }
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  form {
    display: grid;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ${ease.out};

    @media ${device.mobileP} {
      grid-template-areas:
        "name"
        "email"
        "tel"
        "country"
        "postalcode"
        "select"
        "secondary";
    }

    @media ${device.tabletP} {
      grid-template-areas:
        "name name name"
        "email email tel"
        "country country postalcode"
        ". select ."
        "secondary secondary secondary";
    }
  }

  #fcw-name {
    grid-area: name;
  }
  #fcw-email {
    grid-area: email;
  }
  #fcw-tel {
    grid-area: tel;
  }
  #fcw-country {
    grid-area: country;
  }
  #fcw-postal-code {
    grid-area: postalcode;
  }
  #fcw-secondary {
    grid-area: secondary;
  }

  .input-wrapper {
    position: relative;
    margin: 10px;
  }

  .fc-input,
  #fc-country {
    width: 100%;
    border: 0;
    outline: 0;
    min-height: 44px;
    background-color: ${color.greyOpacity};
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 12px 6px;
    box-shadow: none;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }
  .fc-input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .label {
        transform: translateY(-3px) scale(0.75);
        opacity: 0.75;
      }
    }
  }
  #fc-country:valid ~ .label {
    transform: translateY(-3px) scale(0.75);
    opacity: 0.75;
  }

  .label {
    position: absolute;
    left: 12px;
    top: 6px;
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .select-wrapper {
    margin-top: 50px;
    display: grid;
    grid-area: select;
    grid-template-rows: repeat(2, auto);
  }

  .select-wrapper h2 {
    text-align: center;
    ${font.financierRegularM};

    font-size: 2.4rem;
    color: ${color.greyDark};
  }

  #fc-country {
    -webkit-appearance: none;
    border-radius: 0px;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 95% 50%;
    background-color: ${color.greyOpacity};
  }

  #secondary-choice {
    -webkit-appearance: none;
    border-radius: 0px;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 95% 50%;
  }

  .select-wrapper select {
    border: 0;
    outline: 0;
    height: 44px;
    border-bottom: 1px solid ${color.greyDarker};
    ${font.robotoMedium};
    font-size: 1.4rem;
    color: ${color.grey};
    -webkit-appearance: none;
  }

  .select-wrapper option {
    margin: 5px 0;
  }

  #fcw-secondary {
    @media ${device.mobileP} {
      margin: 60px 0;
    }

    @media ${device.tabletP} {
      margin: 50px 25px 0;
    }
  }

  .form-secondary h3 {
    ${font.robotoMedium};
    text-transform: uppercase;
    letter-spacing: 1px;

    @media ${device.mobileP} {
      margin: 0 10px;
    }

    @media ${device.tabletP} {
      margin: 0;
    }
  }

  .category-picker {
    display: grid;

    @media ${device.mobileP} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.tabletP} {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      margin: 10px 40px;
    }
  }

  .category-picker-item.double {
    grid-column-start: span 2;
  }

  .category-picker-item {
    position: relative;
    margin: 5px;
  }

  .category-checkbox {
    opacity: 0;
    background: transparent;
    outline: 0;
    border: none;
    width: 0;
    height: 0;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .category-checkbox {
    &:focus,
    &:not(:placeholder-shown) {
      & + .category-label {
        transform: translateY(0) scale(0.75);
        opacity: 0.75;
      }
    }
  }

  .category-label {
    ${font.robotoMedium};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${color.grey};
    font-size: 1.4rem;
    margin-left: 16px;
    transition: all 0.15s ${ease.out};
    user-select: none;

    @media ${device.tabletP} {
      position: absolute;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .fs-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .fs-checkmark:after {
    position: absolute;
    display: block;
    content: "";
    left: 4px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid ${color.grey};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 0.15s ${ease.out};
  }

  .category-checkbox:checked ~ .fs-checkmark:after {
    border-color: ${color.red};
  }

  .category-checkbox:checked ~ .category-label {
    color: ${color.red};
  }

  .category-checkbox:hover:not(:checked) ~ .fs-checkmark:after {
    border-color: ${color.greyDarker};
  }

  .category-checkbox:hover:not(:checked) ~ .category-label {
    color: ${color.greyDarker};
  }

  .input-wrapper-message {
    position: relative;

    @media ${device.mobileP} {
      margin: 25px 10px;
    }

    @media ${device.tabletP} {
      margin: 25px 0 0;
    }
  }

  .fs-message {
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    padding: 15px 0px;
    outline: 0;
    width: 100%;
    position: relative;
    ${font.robotoMedium};
    letter-spacing: normal;
    color: ${color.greyDark};
    font-size: 1.4rem;
    min-height: 6rem;
    resize: vertical;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fs-message ~ .category-label {
    left: 0;
    margin-left: 0;
    transform: translateY(1.5rem);
    transform-origin: left;
    color: ${color.greyDark};
  }

  .fs-message {
    &:focus,
    &:not(:placeholder-shown) {
      & + .category-label {
        transform: translateY(-0.5rem) scale(0.75);
        margin-left: 1px;
        opacity: 0.75;
      }
    }
  }

  .fs-input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    outline: 0;
    min-height: 44px;
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 1.4rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 12px 6px;
    margin-bottom: 25px;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fs-input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .fs-label {
        transform: translateY(-3px) scale(0.75);
        opacity: 0.75;
      }
    }
  }

  .fs-label {
    position: absolute;
    left: 12px;
    top: 6px;
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 14px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .submit-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .file-upload {
    margin-bottom: 25px;

    @media ${device.tabletP} {
      display: grid;
      align-items: end;
      grid-template-columns: 40% 30%;
      grid-column-gap: 25px;
    }
  }

  #file-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  #file-upload-readonly {
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    padding: 15px 0px 15px 12px;
    outline: 0;
    ${font.robotoMedium};
    letter-spacing: 0.8px;
    color: ${color.greyDark};
    font-size: 1.4rem;
    text-transform: uppercase;
    width: 100%;
    user-select: none;
    cursor: default;

    &::placeholder {
      padding: 6px 0px 0px 0px;
      color: ${color.greyDark};
      font-size: 1.4rem;
    }
  }

  #file-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    min-height: 44px;
    height: 44px;
    width: auto;
    background-color: ${color.greyOpacity};
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    cursor: pointer;
  }

  #fs2-message,
  #fs3-message {
    padding-left: 12px;
    padding-right: 12px;
  }

  #fs2-message + .category-label,
  #fs3-message + .category-label {
    left: 12px;
  }

  .slick-track {
    display: flex;
    flex-direction: row;
  }

  .slick-list {
    width: 100%;
    overflow: hidden;

    @media ${device.mobileP} {
      max-width: 88vw;
    }

    @media ${device.mobileL} {
      max-width: 92vw;
    }

    @media ${device.tabletP} {
      max-width: 95vw;
    }
  }

  #secondary-choice {
    @media ${device.mobileP} {
      margin: 0 10px;
    }

    @media ${device.tabletP} {
      margin: 0;
    }
  }

  .message-label,
  .message-label2,
  .message-label3 {
    @media ${device.mobileP} {
      position: absolute;
    }
  }
`
